@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* background-color: yellow; */
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-title {
	width: 85% !important;
}

.homepage-subtitle {
	width: 85% !important;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 370px;
	height: 370px;
}

.homepage-image-wrapper {
	overflow: hidden;
	border-radius: 10%;
	transform: rotate(3deg);
}

.homepage-image-wrapper img {
	width: 100%;
}

.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	/* margin-left: -30px; */
}

.homepage-article {
	padding-bottom: 1px;
}

.homepage-article-more-link {
	margin-top: 10px;
	width: 100%;	
	font-size: 13px;
	height: 30px;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 10px;
	&:hover{
		background-color: var(--hover-background);
	}
}

.homepage-article-more-link a {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--link-color);
	text-decoration: none;
}

.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin-top: 10px;
		text-align: center;
		order: 2;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		text-align: center;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-socials {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;		
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
		width: 100%;
	}

	.homepage-article {
		flex-basis: auto;
		width: 100%;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -15px;
		margin-right: -15px;
	}
}
