@import "../../../data/styles.css";

.article {
	display: flex;
}

.article a {
	text-decoration: none;
}

.article-left-side {
	min-width: 20%;
	max-width: 20%;	
}

.article-right-side {
	flex-basis: 80%;
	min-width: 700px;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	padding: 25px;
	margin: 5px;
}

.article-right-side:hover {
	background: var(--hover-background);
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.article-date {
	padding-top: 30px;
	font-size: 15px;
	color: var(--tertiary-color);
}

.article-title {
	color: var(--secondary-color);
	font-size: 16px;
	font-weight: 600;
}

.article-meta {
	color: var(--secondary-color);
	margin-top: 4px;
	font-size: 12px;
	font-weight: 300;
}

.article-hero{
	width: 100%;
	margin-top: 30px;
	border-radius: 10px;
}

.article-description {
	padding-top: 10px;
	font-size: 12px;
	color: var(--secondary-color);
	font-weight: 480;
	line-height: 25px;
}

.article-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

@media (max-width: 1024px) {
	.article{
		flex-direction: column;
	}

	.article-left-side {
		/* flex-basis: 50%; */
		min-width: 80%;
		max-width: 80%;
		/* flex-direction: column;
		width: 100%;
		order: 2; */
	}

	.article-right-side {
		/* flex-basis: 50%; */
		min-width: 85%;
		max-width: 85%;
		padding: 5px;
		margin: 5px;
	}
}
