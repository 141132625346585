.articles-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.articles-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 99;
}

.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}

.articles-article {
	padding-top: 0px;
	padding-left: 35px;
	padding-bottom: 20px;
	/* border-left: 2px solid #f4f4f5; */
	position: relative;
	width: 100%;
}

.articles-article::before {
	content: "";
	position: absolute;
	left: -4px;
	/* left: 0; */
	top: 0;
	width: 10px;
	height: 10px;
	background-color: #616161;
	border-radius: 50%; /* Makes the shape a circle */
	box-shadow: 0 0 0 2px #616161; /* Creates the solid circle */
}

.articles-article::after {
	content: "";
	position: absolute;
	left: 0;
	top: 10px; /* Start from the bottom of the circle */
	bottom: 20px; /* Shortens the line from the bottom by 20px */
	border-left: 2px solid #616161;
}


@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}
}
