@import "../../../data/styles.css";

.member {
	/* background: var(--hover-background); */
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
}

.member a {
	text-decoration: none;
}

.member:hover {
	background: var(--hover-background);
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.member:hover .member-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.member-container {
	padding: 30px;
}

.member-logo {
	width: 100%;
}

.member-logo img {
	width: 100%;
	border-radius: 5px;
}

.member-title {
	font-family: var(--primary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.member-description {
	color: var(--secondary-color);
}

.member-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.member-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.member-link-text {
	padding-left: 20px;
	font-weight: 700;
}

@media (max-width: 600px) {
	.member {
		height: 300px;
	}
}
